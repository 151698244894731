<template>
	<div class="warpper">
		<div class="page-box">
			<div class="dfc-head">
				<div class="black-bg"></div>
				<router-link to="/h5" class="btn-home">返回首页</router-link>
			</div>
			<div class="dfc-news-main">
				<div class="title-news"></div>
			</div>
			<div class="mod-content">
				<div class="detail-panel">
					<p class="news-detail-title">{{details.title}}</p>
					<p class="news-detail-time">{{details.date}}</p>
					<div class="news-detail-content" v-html="details.content"></div>
          <div class="footer">
            <div class="main5">
              <div class="footer-top footer-title">
                <p>本网络游戏适合年满18岁（含）以上的玩家使用 开发者:张家口卡洛斯网络技术有限公司 | 版本号：1.1 | 更新时间：2021.1.8</p>
                <p>抵制不良游戏,拒绝盗版游戏。注意自我保护,谨防受骗上当。适度游戏益脑,沉迷游戏伤身。合理安排时间,享受健康生活。</p>
                <p>张家口卡洛斯网络技术有限公司 | COPYRIGHT © 2019-2021 ALL RIGHTS RESERVED</p>
                <br />
                <p>点击加群 - <a style="color: #7bf5e0" target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=zmFb19J1u8BACJb2oxRTIsk0sm6R0YYL&jump_from=webapi">官方1群</a>
                  | <a style="color: #7bf5e0" target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=D8MIp05k1z490PLOtEdQyj9nsCZ2QSd5&jump_from=webapi">官方2群</a>
                  | <a style="color: #7bf5e0"  target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=QeppcUXJol1DgtMOdIAnS7jkTVqiW78Y&jump_from=webapi">官方3群</a>
                </p>


                <p><a href="#">企业QQ：正在开通（9:00-21:00）</a>	</p>
                <p><a href="#">微信公众号：决战时空风暴</a> </p>
                <p><a href="#">微信小程序：时空助手</a> </p>

              </div>

<!--              <div class="footer-bottom">-->

<!--                <div class="page-box">-->
<!--                  <router-link target="_blank" to='/pc/copy?type=0'>公司简介</router-link>-->
<!--                  <span>-</span>-->
<!--                  <router-link target="_blank" to='/pc/copy?type=4'>联系我们</router-link>-->
<!--                  <span>-</span>-->
<!--                  <router-link target="_blank" to='/pc/copy?type=6'>加入我们</router-link>-->
<!--                </div>-->
<!--                <div class="page-box">-->
<!--                  <p><a href="https://skfbxy.52wgame.com/#/pages/xy/xy">用户协议</a> |-->
<!--                    <router-link target="_blank" to='/pc/copy?type=7'>隐私政策及儿童个人信息保护规则</router-link></p>-->

<!--                  <p><a target="_blank" :href="require('../../assets/img/yyzz.jpg')">营业执照</a> ICP备案：冀ICP备19025191号-3 电信增值业务许可证：<a :href="require('../../assets/img/icpba.jpg')"">冀b2-20190755</a></p>-->
<!--                  <p> 运营单位：杭州群游科技有限公司 著作权人：张家口卡洛斯网络技术有限公司 出版物号：ISBN 978-7-7979-9582-5 出版单位：杭州群游科技有限公司</p>-->
<!--                  <p> 违法及不良信息举报中心 </p>-->
<!--                  &lt;!&ndash; <div style="width:300px;margin:0 auto; padding:20px 0;">-->
<!--                    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13073002000090" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">冀公网安备 13073002000090号</p></a>-->
<!--                  </div> &ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
export default {
	name: 'Home',
	data() {
		return {
			details:{}
		};
	},
	components: {},

	mounted() {
		// this.details = JSON.parse(localStorage.getItem('nactive'));
		// console.log(this.details)
	},
	created() {
		this.details = JSON.parse(localStorage.getItem('nactive'));
		console.log(this.details)
	},
	
	methods: {}
};
</script>
<style scoped>
	.mod-content .detail-panel .news-detail-title {
	    font-size: 1.5rem;
		padding: 2rem 0 1rem;
		text-align: center;
	}
	.mod-content .detail-panel .news-detail-time {
	    position: relative;
	    font-size: 1.2rem;
	}
	.mod-content .detail-panel .news-detail-content {
	    font-size: 1rem;
	    text-align: left;
	    margin-top: 2rem;
	    padding: 1.8rem;
	    line-height: 1.8em;
	    border-top: .01rem solid grey;
	}
	.mod-content .detail-panel {
	    width: 100%;
	    background-color: #0b0715;
	    text-align: center;
	    font-family: "Microsoft Yahei";

		
	    color: #e2e2e2;
	}
.page-box {
	background: url(../../assets/img/news-bg.jpg) no-repeat center top;
	background-size: cover;
	width: 100%;
	margin: 0 auto;
}
.dfc-news-main .title-news {
	margin: 0.65rem auto 0.3rem;
	background: url(../../assets/img/title-news.png) no-repeat center;
	height: 4rem;
	background-size: contain;
}
.dfc-head {
	width: 100%;
	height: 5rem;
}
.dfc-head .black-bg {
	position: absolute;
	width: 100%;
	height: 5rem;
	background: radial-gradient(black, transparent);
	opacity: 0.3;
}
.dfc-head .btn-home {
	position: absolute;
	top: 1.5rem;
	right: 2rem;
	height: 2rem;
	width: 5rem;
	background-color: #78f0de;
	border-radius: 5px;
	font-size: .8rem;
	line-height: 2rem;
	text-align: center;
}
.dfc-head a {
	text-decoration: none;
}
.dfc-head .logo {
	position: absolute;
	top: 1.2rem;
	left: 2rem;
	background: url(../../assets/img/page1_img2.png);
	width: 5rem;
	height: 3rem;
	background-size: contain;
}
.footer {
	padding: 0.3rem 0 0.5rem;
	width: 100% !important;
	opacity: 0.8;
	background: #000000;
}

.footer .main5 {
	position: relative;
	width: 90%;
	height: auto;
	margin: 0 auto;
	color: #fff;
	z-index: 2;
}

.footer_logo {
	display: block;
	float: left;
	width: 1.5rem;
	margin: 0 1rem;
}
.footer-top {
		height: auto;
		overflow: hidden;
		margin: 0 auto;
		text-align: center;
	}
	
	.footer-title {
		float: left;
		font-size: 0.3rem;
		font-weight: 400;
		color: #ffffff;
		padding-top: 0.2rem;
	}
	
	.footer-bottom {
		text-align: center;
		font-size: 0.35rem;
		font-weight: 400;
		padding-top: 20px;
		color: #ffffff;
	}
	
	.footer-bottom a{
		color: #FFFFFF;
	}
	.footer-bottom p:first-child {
		padding-bottom: 0.2rem;
	}
.footer-top a{
	color: #fff;
}
.footer-margin {
	margin: 0 auto;
}
</style>
